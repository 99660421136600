:root {
  --white: #ffffff;

  --gray-1: #f6f6fa;
  --gray-2: #efeef4;
  --gray-3: #e9e9e9;
  --gray-4: #c4c4c4;
  --gray-5: #e6e6e6;
  --gray-6: #cbc9d1;
  --gray-lightest: #e6e5eb;
  --gray-light: #85848e;
  --gray-dark: #3e3d49;
  --gray-darkest: #0b081f;

  --gray-thin: #f1f1f1;

  --cyan: #0cc8ff;

  --violet: #7e3aff;
  --violet-100: #7e3aff1a;

  --green: #00e075;
  --green-1: #b5f6da;
  --green-2: #009d59;

  --red: #ff3131;
  --red-1: #f44235;
  --red-2: #ff0707;
  --red-3: #ff1c1c;

  --blue: #3549f5;
  --blue-1: #31a7f5;
  --blue-2: #a9c1fd;

  --brown: #785346;

  --yellow: #ffd231;
  --yellow-1: #ffc113;
  --yellow-2: #e0bc00;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~@peacock-app/ui/build';
@import-normalize;

body {
  width: 100vw;
  min-width: 100vw;

  height: 100vh;
  min-height: 100vh;
  overflow-y: scroll;

  & div:-moz-focusring {
    outline: none;
    border: 0;
  }
  & button {
    outline: none !important;
  }
  .ant-modal {
    @media (max-width: 768px) {
      max-width: calc(100vw - 30px) !important;
    }
  }
}

#root {
  // width: 100vw;
  height: 100vh;
  max-width: 100%;
}

.table-scrollbar {
  &::-webkit-scrollbar {
    height: 4px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(126, 58, 255, 0.05);
    border-radius: 50px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--violet);
    border-radius: 50px;
  }
}
